import { openExternalLink } from '~/shared/openExternalLink';

/** For banno integrations there is no direct way to open external links in terms of mobile app.
 * For this purpose we try to open external links manually via window.open and if it was failed
 * send post message to upper app layer */
export const registerLinksHandler = (container = document.body) => {
  const listener = (e: MouseEvent) => {
    const targetElement = e.target as HTMLElement;
    const tagName = (targetElement.tagName || '').toLowerCase();

    if (tagName === 'a') {
      const anchorElement = targetElement as HTMLAnchorElement;
      const targetValue = (anchorElement.target || '').toLowerCase();
      if (targetValue === 'blank' || targetValue === '_blank') {
        e.preventDefault();
        openExternalLink(anchorElement.href);
      }
    }
  };

  container.addEventListener('click', listener);

  return () => {
    container.removeEventListener('click', listener);
  };
};
