import { useMemo } from 'react';
import { pipe } from 'fp-ts/function';
import * as RD from '@devexperts/remote-data-ts';

import { FirstDepositStatus, useActivePortfolioInfo } from '~/entities/protfolio';

export const useIsPortfolioFunded = () => {
  const [activePortfolioInfo] = useActivePortfolioInfo();

  return useMemo(
    () =>
      pipe(
        activePortfolioInfo,
        RD.map((portfolioInfo) => portfolioInfo.firstDepositStatus === FirstDepositStatus.PROCESSED),
        RD.getOrElse(() => false),
      ),
    [activePortfolioInfo],
  );
};
