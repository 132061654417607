import { useEffect, useRef } from 'react';
import { constVoid } from 'fp-ts/function';

import { useActivePortfolioInfo } from '~/entities/protfolio';

import { useUpdateActivePortfolio } from '../useUpdateActivePortfolio';

type Updaters = {
  updatePortfolios: VoidFunction;
  updateActivePortfolioInfo: (keepCache?: boolean) => void;
};

const defaultRefs: Updaters = {
  updatePortfolios: constVoid,
  updateActivePortfolioInfo: constVoid,
};

/* 1 minute */
const UPDATE_INTERVAL = 60 * 1000;

export const usePortfoliosUpdateEffects = () => {
  const updateActivePortfolio = useUpdateActivePortfolio();
  const [_, updatePortfoliosInfo] = useActivePortfolioInfo();
  const updatersRefs = useRef(defaultRefs);

  useEffect(() => {
    const interval = setInterval(() => {
      updatersRefs.current.updatePortfolios();
      updatersRefs.current.updateActivePortfolioInfo(true);
    }, UPDATE_INTERVAL);

    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    updatersRefs.current.updatePortfolios = updatePortfoliosInfo;
    updatersRefs.current.updateActivePortfolioInfo = updateActivePortfolio;
  }, [updatePortfoliosInfo, updateActivePortfolio]);
};
