import { init, browserTracingIntegration, replayIntegration } from '@sentry/react';
import { IS_DEV_BUILD_ENV, RUNTIME_ENV, SENTRY_DSN } from '@constants/constants';

export function configSentry() {
  init({
    release: 'eko-frontend',
    dsn: IS_DEV_BUILD_ENV ? '' : SENTRY_DSN,
    environment: RUNTIME_ENV,
    integrations: [browserTracingIntegration(), replayIntegration()],

    /*
     * Set tracesSampleRate to 1.0 to capture 100%
     * of transactions for performance monitoring.
     * We recommend adjusting this value in production
     */
    tracesSampleRate: IS_DEV_BUILD_ENV ? 1 : 0.1,

    /*
     * Capture Replay for 10% of all sessions,
     * plus for 100% of sessions with an error
     */
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 0,
    debug: IS_DEV_BUILD_ENV,
  });
}
