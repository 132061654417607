import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Switch } from 'react-router-dom';

import routesMeta from './meta';
import Loader from '~/ui/components/Loader/Loader';
import * as analytics from '~/utils/analytics';
import * as selectors from '@store/profile/selectors';
import { useSelector } from 'react-redux';
import { selectIsExternalUser } from '@store/application/selectors';
import { useVisits } from '~/domain/effects/application';

const getRoutes = (context) =>
  routesMeta.filter((route) => route.hasAccess(context)).map((route) => route.getComponent(context));

export default function Routes() {
  const isExternalUser = useSelector(selectIsExternalUser);

  useVisits();

  useEffect(() => {
    analytics.page();
  }, []);

  const hasAlpacaAccount = selectors.getHasAlpacaAccount();
  const closure = selectors.getIsAccountClosure();

  const ctx = useMemo(
    () => ({
      hasAlpacaAccount,
      closure,
      isExternalUser,
    }),
    [hasAlpacaAccount, closure, isExternalUser],
  );

  const [initialMount, setInitialMount] = useState(false);

  /**
   * Suspense renders its children in a background with "display: none" inline style what leads to possible
   * incorrect layout calculations.
   * Need to trigger re-render content after initial loading was succeed to initialize properly layouts' calculations
   * */
  const onLoaderUnMounted = useCallback(() => {
    setInitialMount(true);
  }, []);

  const content = useMemo(() => getRoutes(ctx), [ctx, initialMount]);

  return (
    <React.Suspense fallback={<Loader onUnMount={onLoaderUnMounted} />}>
      <Switch>{content}</Switch>
    </React.Suspense>
  );
}
