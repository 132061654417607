import ReactDOM from 'react-dom';

import AppProvider from 'src/AppProvider';
import { configSentry } from '~/services/sentry';
import { isDev } from './constants/application';

configSentry();

ReactDOM.render(<AppProvider />, document.getElementById('app'));

// eslint-disable-next-line no-undef
if (isDev && typeof module !== 'undefined' && module && module.hot && typeof module.hot.accept === 'function') {
  // eslint-disable-next-line no-undef
  module.hot.accept();
}
