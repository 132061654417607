const postMessageOpenLink = (link?: string) => {
  try {
    window.parent.postMessage(JSON.stringify({ event: 'eko-open-external-link', link }), '*');
  } catch {
    console.error('Unable to send a post message');
  }
};

export const openExternalLink = (link?: string) => {
  try {
    const result = window.open(link, '_blank');
    if (!result) {
      postMessageOpenLink(link);
    }
  } catch {
    postMessageOpenLink(link);
  }
};
