import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import * as Tecton from 'q2-tecton-sdk';
import { ScrollData } from 'q2-tecton-sdk/dist/esm/sources/platformScrollChanged';
import * as RD from '@devexperts/remote-data-ts';
import { pipe } from 'fp-ts/function';

import { useUserSettings } from '~/shared/hooks/useUserSettings';
import { modifyUserSettingAction } from '@store/settings';
import { selectOrganization } from '@store/organization/selectors';

import { handleOuterHeightNaive, keepAlive, handleOuterHeightAware, registerOnScrollChangedExperiment } from './utils';

type TectonHandlersType =
  | 'unknown'
  | 'registerOnScrollChangedExperiment'
  | 'handleOuterHeightAware'
  | 'handleOuterHeightNaive';

declare global {
  interface Window {
    tecton?: Tecton.IPlatformCoreAPI;
    ekoScrollOutsideToTop: VoidFunction;
    __tectonType?: TectonHandlersType;
    __enable_new_tecton?: VoidFunction;
    __disable_new_tecton?: VoidFunction;
    Tecton: {
      device: 'phone' | 'tablet' | 'desktop';
      inMobileApp?: boolean;
      platformDimensions?: ScrollData & { innerWidth: number };
    };
  }
}

window.ekoScrollOutsideToTop = () => {
  // eslint-disable-next-line no-console
  console.log('called default scrollOutsideToTop');
};

const Q2Id = 1;

const moduleIdParam = 'tct-id=tectonRoute.customsso.Main';

const registerTectonCallbacks = (tectonInstance: Tecton.IPlatformCoreAPI, isTectonExperiment: boolean) => {
  window.ekoScrollOutsideToTop();

  window.__tectonType = 'unknown';

  if (tectonInstance.sources?.platformScrollChanged) {
    if (isTectonExperiment) {
      window.__tectonType = 'registerOnScrollChangedExperiment';
      registerOnScrollChangedExperiment(tectonInstance);
      return;
    }

    window.__tectonType = 'handleOuterHeightAware';
    handleOuterHeightAware(tectonInstance);
    return;
  }

  window.__tectonType = 'handleOuterHeightNaive';
  handleOuterHeightNaive(tectonInstance);
};

type Location = ReturnType<typeof useLocation>;
type History = ReturnType<typeof useHistory>;

const loadTecton = (location: Location, history: History, isTectonExperiment: boolean) => {
  let urlWithModuleId;

  if (location.search) {
    if (location.search.indexOf('tct-id') === -1) {
      urlWithModuleId = location.pathname + location.search + `&${moduleIdParam}`;
    }
  } else {
    urlWithModuleId = location.pathname + '?' + moduleIdParam;
  }

  void (urlWithModuleId && history.push(urlWithModuleId));

  Tecton.connect({
    testOptions: window.parent === window ? { loadElements: false } : undefined,
  })
    .then((tectonInstance) => {
      window.tecton = tectonInstance;

      window.ekoScrollOutsideToTop = () => {
        // eslint-disable-next-line no-console
        console.log('called real scrollOutsideToTop');

        if (typeof window.tecton?.actions?.scrollToTop === 'function') {
          window.tecton.actions.scrollToTop();
        }
      };

      document.documentElement.setAttribute(
        'device',
        window.Tecton && window.Tecton.device ? window.Tecton.device : 'unknown',
      );

      document.documentElement.setAttribute(
        'isMobileApp',
        window.Tecton && window.Tecton.inMobileApp ? window.Tecton.inMobileApp.toString() : 'true',
      );

      registerTectonCallbacks(tectonInstance, isTectonExperiment);

      keepAlive(tectonInstance);
      document.body.removeAttribute('data-tecton-module');
      tectonInstance.actions?.setFetching(false);
      tectonInstance.actions?.resizeIframe();
    })
    .catch((e) => {
      console.error('Failed to load Tecton', e);
    });
};

export const useTecton = () => {
  const organization = useSelector(selectOrganization);
  const location = useLocation();
  const history = useHistory();

  const userSettings = useUserSettings();

  const dispatch = useDispatch();

  useEffect(() => {
    window.__enable_new_tecton = () => {
      dispatch(
        modifyUserSettingAction({
          experiments: ['new_tecton' as const],
        }),
      );
    };

    window.__disable_new_tecton = () => {
      dispatch(
        modifyUserSettingAction({
          experiments: [],
        }),
      );
    };
  }, [dispatch]);

  useEffect(
    () => {
      const isTectonExperiment: boolean | undefined = pipe(
        userSettings,
        RD.fold(
          () => undefined,
          () => undefined,
          () => false,
          (settings) => ((settings && settings.experiments) || []).includes('new_tecton'),
        ),
      );

      if (organization?.vendor === Q2Id && isTectonExperiment !== undefined) {
        // TODO double check if experiment fits Q2 api
        loadTecton(location, history, true);
        // loadTecton(location, history, isTectonExperiment);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [organization, userSettings, history],
  );
};
