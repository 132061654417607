export const SET_LANG = 'SET_LANG';
export const SET_DOMAIN = 'SET_DOMAIN';
export const SET_COMPANY_DETAILS = 'SET_COMPANY_DETAILS';

// APP
export const SET_TITLE = 'SET_TITLE';
export const SET_STYLES = 'SET_STYLES';
export const SET_LOGO = 'SET_LOGO';
export const SET_DISPLAY_NAME = 'SET_DISPLAY_NAME';
export const SET_EMOJI_ON = 'SET_EMOJI_ON';
export const SET_SELECTION_TYPE = 'SET_SELECTION_TYPE';
export const SET_PASSWORD_CHANGE_AVAILABILITY = 'SET_PASSWORD_CHANGE_AVAILABILITY';
