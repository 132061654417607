import { response } from '@api/helpers';
import brokerApi from '@api/endpoints/broker';
import waitlistApi from '@api/endpoints/waitlist';
import * as brokerSchemas from '@api/schemas/broker';
import { BrokerageAccountDTO } from '~/entities/brokerageAccount';

export function alpacaOnboarding(payload: brokerSchemas.CreateAlpacaAccountInfoDTO) {
  return async () => response(brokerApi.onboarding(payload));
}

export function alpacaAccountInfo() {
  return async () => response(brokerApi.fetchAlpacaAccount());
}

export function editAlpacaAccountInfo(payload: Partial<BrokerageAccountDTO>) {
  return async () => response(brokerApi.changeAlpacaAccount(payload));
}

export function getBrokerageAccountData() {
  return async () => response(brokerApi.fetchBrokerAgeAccount());
}

export function waitlistRegister(payload: { country: string }) {
  return async () => response(waitlistApi.register(payload));
}

export function submitDocuments(payload: object) {
  return async () => response(brokerApi.submitDocuments(payload));
}

export function closeAccount(payload: brokerSchemas.CloseAccountParams) {
  return async () => response(brokerApi.closeAccount(payload));
}
