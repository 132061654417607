import { IPlatformCoreAPI } from 'q2-tecton-sdk';
import { debounce } from 'lodash';

import { callMobileFunction } from './callMobileFunction';
import { dispatchScrollEvent } from './dispatchScrollEvent';

let isTouchInside = false;

const debouncedTouchEnd = debounce(() => {
  isTouchInside = false;
}, 500);

document.addEventListener('touchstart', () => {
  isTouchInside = true;
});

document.addEventListener('touchend', debouncedTouchEnd);

export const handleOuterHeightAware = (tecton: IPlatformCoreAPI) => {
  if (!tecton.sources || !tecton.sources.platformScrollChanged) {
    return;
  }
  tecton.sources.platformScrollChanged(({ scrollY, innerHeight }) => {
    const root = document.documentElement;
    const app = document.querySelector<HTMLElement>('#app');
    if (window.innerWidth < 1024) {
      root.style.setProperty('--q2-real-top', `${scrollY}px`);
      root.style.setProperty(
        '--q2-viewport-height',
        `calc(${innerHeight}px - var(--q2-header-size) + var(--q2-real-top))`,
      );
      const main = app?.querySelector('main');
      if (main) {
        main.style.marginTop = `var(--q2-real-top)`;
      }
      if (app) {
        app.style.height = `calc(${innerHeight}px - var(--q2-header-size) + var(--q2-real-top))`;
      }
      void (isTouchInside && window.ekoScrollOutsideToTop());
    } else {
      root.style.setProperty('--q2-real-top', '0px');
      if (app) {
        app.style.height = 'unset';
      }
    }
    tecton.actions?.resizeIframe();
    dispatchScrollEvent();
  });

  document.addEventListener('focusout', () => {
    callMobileFunction(tecton.actions?.scrollToTop);
  });
};
