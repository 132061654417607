import { SET_LANG, SET_COMPANY_DETAILS } from '~/types/types';
import { DEFAULT_LANG, DEFAULT_LANG_ROUTE } from '@constants/constants';
import { Action } from 'redux';

const initialState = {
  lang: DEFAULT_LANG,
  companyDetailsVisible: false,
  alpacaSignupStatus: null,
  /* not needed ? */
  media: {
    width: 0,
    height: 0,
  },
  langMap: {},
  langRoute: DEFAULT_LANG_ROUTE,
  authorized: false,
};

type ApplicationAction =
  | (Action<typeof SET_LANG> & {
      payload: typeof DEFAULT_LANG;
    })
  | (Action<typeof SET_COMPANY_DETAILS> & {
      payload: boolean;
    });

export default function (state = initialState, action: ApplicationAction) {
  switch (action.type) {
    case SET_LANG:
      return { ...state, lang: action.payload };
    case SET_COMPANY_DETAILS:
      return { ...state, companyDetailsVisible: action.payload };
    default:
      return state;
  }
}
