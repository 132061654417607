import { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import * as RD from '@devexperts/remote-data-ts';
import { pipe } from 'fp-ts/function';

import { altOnError } from '@utils/RemoteData';
import { modifyUserSettingAction } from '@store/settings';
import { useUserSettings } from '../useUserSettings';

declare global {
  interface Window {
    __enable_app: VoidFunction;
    __disable_app: VoidFunction;
  }
}

export const useIsAppDisabled = () => {
  const userSettings = useUserSettings();

  const dispatch = useDispatch();
  useEffect(() => {
    window.__enable_app = () => {
      dispatch(
        modifyUserSettingAction({
          isAppDisabled: false,
        }),
      );
    };

    window.__disable_app = () => {
      dispatch(
        modifyUserSettingAction({
          isAppDisabled: true,
        }),
      );
    };
  }, [dispatch]);

  return useMemo(
    () =>
      pipe(
        userSettings,
        RD.map(({ isAppDisabled }) => {
          return isAppDisabled === undefined ? false : isAppDisabled;
        }),
        altOnError(() => RD.success(false)),
      ),
    [userSettings],
  );
};
