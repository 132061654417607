const isIos = /iPod|iPhone|iPad/.test(navigator.userAgent) || /iPod|iPhone|iPad/.test(navigator.platform);
const isAndroid = /Android/.test(navigator.userAgent);

export const callIOSFunction = (f?: VoidFunction) => {
  if (isIos && f) {
    f();
  }
};

export const callAndroidFunction = (f?: VoidFunction) => {
  if (isAndroid && f) {
    f();
  }
};

export const callMobileFunction = (f?: VoidFunction) => {
  callIOSFunction(f);
  callAndroidFunction(f);
};
