import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { pipe } from 'fp-ts/function';
import * as RD from '@devexperts/remote-data-ts';

import { useActivePortfolioInfo } from '~/entities/protfolio';
import { setHoldingsAction, useUpdatePortfolioHoldings } from '~/entities/Holding';

export const useUpdateActivePortfolio = () => {
  const [activePortfolioInfo, updatePortfolios] = useActivePortfolioInfo();
  const updateActivePortfolio = useUpdatePortfolioHoldings();
  const dispatch = useDispatch();

  useEffect(() => {
    if (RD.isFailure(activePortfolioInfo)) {
      dispatch(setHoldingsAction(RD.failure({ code: 404, message: 'No portfolio info' })));
    }
    if (RD.isPending(activePortfolioInfo)) {
      dispatch(setHoldingsAction(RD.pending));
    }
  }, [activePortfolioInfo, dispatch]);

  const activePortfolioId = useMemo(
    () =>
      pipe(
        activePortfolioInfo,
        RD.map(({ id }) => id),
        RD.getOrElse(() => ''),
      ),
    [activePortfolioInfo],
  );

  return useCallback(
    (keepCache = false) => {
      if (activePortfolioId) {
        updateActivePortfolio(activePortfolioId, keepCache);
      } else {
        updatePortfolios();
      }
    },
    [updatePortfolios, updateActivePortfolio, activePortfolioId],
  );
};
