import { useSelector } from 'react-redux';
import { Eq, eqString } from 'fp-ts/Eq';
import * as A from 'fp-ts/Array';
import * as RD from '@devexperts/remote-data-ts';

import { ApiError } from '@models/ApiError';
import { userSettingsSelector, UserSettingsRD, UserSettings } from '@store/settings';

const experimentsEq = A.getEq(eqString);

const userSettingsEq: Eq<UserSettingsRD> = RD.getEq<ApiError, UserSettings>(
  { equals: (a, b) => a.code === b.code },
  {
    equals: (a, b) => {
      let experimentsEqual = false;
      if (a.experiments && b.experiments) {
        experimentsEqual = experimentsEq.equals(a.experiments, b.experiments);
      }

      if (a.experiments === b.experiments) {
        experimentsEqual = true;
      }

      return experimentsEqual && a.isAppDisabled === b.isAppDisabled;
    },
  },
);

export const useUserSettings = () => {
  return useSelector(userSettingsSelector, userSettingsEq.equals);
};
